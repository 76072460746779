import request from "@/util/request";

export function listGameType() {
    return request({
        url: '/gameType/list',
        method: 'get'
    })
}

export function saveOrUpdateGameType(data) {
    return request({
        url: '/gameType/saveOrUpdate',
        method: 'post',
        data: data
    })
}

export function deleteGameType(id) {
    return request({
        url: '/gameType/deleteById',
        method: 'delete',
        params: {id}
    })
}