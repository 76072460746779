import request from "@/util/request";

export function list() {
    return request({
        url: '/seo/list',
        method: 'get'
    })
}

export function saveOrUpdate(data) {
    return request({
        url: '/seo/saveOrUpdate',
        method: 'post',
        data: data
    })
}