<template>
  <el-result title="404" sub-title="对不起，您访问的页面不存在">
    <template #icon>
      <img
          src="../assets/404.svg"
          width="256"
          height="228"
      />
    </template>
    <template #extra>
      <el-button type="primary" @click="backToHome">回到首页</el-button>
    </template>
  </el-result>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "404",
  methods: {
    backToHome() {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>

</style>