<template>
  <div>

      <el-result
          v-if="false"
          title="该功能开发中"
          sub-title="敬请期待"
          style="height: 600px"
      >
        <template #icon>
          <el-image src="http://120.48.133.253/website-api/download/maintain.svg" style="height: 450px;width: 500px;" />
        </template>
        <template #extra>
          <el-button size="large" type="primary" @click="backToHome">返回首页</el-button>
        </template>
      </el-result>

    <el-card style="width: 60%">

      <div>

        <div style="width: 100%;display: flex;justify-content: space-between">
          <span>
            <el-icon><Connection /></el-icon>
            <strong>&nbsp;SEO 配置</strong>
          </span>
        </div>

        <el-table stripe :data="seoList" empty-text="暂无数据" style="margin-top: 10px;">
          <el-table-column prop="pageName" label="页面名称" width="200" />
          <el-table-column prop="pageTitle" label="网页标题" />
          <el-table-column prop="pageDescription" label="网页简介" />
          <el-table-column prop="pageKeyword" label="关键词" />
          <el-table-column label="操作" width="120">
            <template #default="scope">
              <el-button
                  type="primary"
                  @click="handleEdit(scope.row)"
                  icon="Edit"
                  text
              >
                编辑
              </el-button>
            </template>
          </el-table-column>
        </el-table>

      </div>

    </el-card>

    <el-dialog
        :title="title"
        v-model="openEdit"
        @close="this.form = {}"
        style="width: 40%;"
    >
      <el-form :model="form" label-width="120px">
        <el-form-item label="页面名称">
          <el-input v-model="form.pageName" disabled style="width: 50%" maxlength="20" show-word-limit/>
        </el-form-item>

        <el-form-item label="网页标题">
          <el-input v-model="form.pageTitle" style="width: 100%" maxlength="20" show-word-limit/>
        </el-form-item>

        <el-form-item label="网页简介">
          <el-input v-model="form.pageDescription" style="width:100%;"/>
        </el-form-item>

        <el-form-item label="关键词">
          <el-input v-model="form.pageKeyword" style="width:50%;"/>
        </el-form-item>

      </el-form>

      <template #footer>
            <span class="dialog-footer">
              <el-button @click="cancel">取消</el-button>
              <el-button type="primary" :loading="loading" @click="confirm">提交</el-button>
            </span>
      </template>

    </el-dialog>


  </div>
</template>

<script>
import { Plus, Close } from '@element-plus/icons-vue'

import {list, saveOrUpdate} from '@/api/seo'
import {ElMessage} from "element-plus";


export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Seo",
  components: {

  },
  data() {
    return {
      title: '',
      openEdit: false,
      form: {},
      formData: {},
      fileDetailList: [],
      seoList: [],
    }
  },
  created() {
    this.getList()
  },
  methods: {

    cancel() {
      this.openEdit = false
    },

    getList() {
      list().then(resp => {
        this.seoList = resp.data.data
      })
    },

    backToHome() {
      this.$router.replace('/')
    },

    handleEdit(row) {
      this.title = '编辑 SEO 配置'
      this.form = row
      this.openEdit = true
    },

    confirm() {
      saveOrUpdate(this.form).then(resp => {
        ElMessage({
          type: 'success',
          message: '修改成功',
          duration: 1000
        })
        this.form = {}
        this.getList()
        this.openEdit = false
      })
    }

  }
}
</script>

<style scoped>
.el-upload {
  --el-upload-dragger-padding-horizontal: 35px;
  --el-upload-dragger-padding-vertical: 0px;
}

.el-upload-dragger {
  background-color: rgba(0, 0, 0, 0);
}

body {
  background-color: white;
}
</style>