<!-- 新闻中心 -->

<template>
  <div>
    <el-card>
      <div>
        <div style="width: 100%;display: flex;justify-content: space-between">
          <span>
            <el-icon><Box /></el-icon>
            <strong>&nbsp;产品中心</strong>
          </span>

          <span style="width: 30%">
            <el-select v-model="queryParam.productShowTypeId" clearable placeholder="产品分类" style="width: 36%">
              <el-option
                  v-for="item in productShowTypeList"
                  :key="item.id"
                  :label="item.typeName"
                  :value="item.id"
              />
            </el-select>
            <el-input style="width: 36%;margin-left: 2%;" clearable v-model="queryParam.productName" placeholder="搜索" prefix-icon="Search" @keyup.enter="getList" />
            <el-button style="width: 22%;margin-left: 2%" @click="getList">搜索</el-button>
          </span>

        </div>


        <div style="margin: 10px;">
          <el-button type="primary" icon="Plus" @click="handleAdd">新增</el-button>
        </div>

        <el-table stripe :data="newsList" style="width: 100%;min-height: 550px;" empty-text="暂无数据">
          <el-table-column prop="sort" label="序号" width="100" />
          <el-table-column prop="productShowTypeId" label="产品分类" width="120">
            <template #default="scope">
              {{ productShowTypeList.filter(type => type.id == scope.row.productShowTypeId).length > 0 ? productShowTypeList.filter(type => type.id == scope.row.productShowTypeId)[0].typeName : '' }}
            </template>
          </el-table-column>
          <el-table-column prop="productName" label="产品名称" width="200" />
          <el-table-column prop="productBrief" label="产品简介"  />

          <el-table-column prop="isHot" label="爆款" width="100">
            <template #default="scope">
              <el-tag
                  :type="scope.row.isHot? 'success' : 'info'"
                  disable-transitions
              >{{ scope.row.isHot ? '是' : '否' }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="isNew" label="新品" width="100">
            <template #default="scope">
              <el-tag
                  :type="scope.row.isNew? 'success' : 'info'"
                  disable-transitions
              >{{ scope.row.isNew ? '是' : '否' }}</el-tag>
            </template>
          </el-table-column>

          <el-table-column prop="isSellWell" label="畅销" width="100">
            <template #default="scope">
              <el-tag
                  :type="scope.row.isSellWell? 'success' : 'info'"
                  disable-transitions
              >{{ scope.row.isSellWell ? '是' : '否' }}</el-tag>
            </template>
          </el-table-column>

          <el-table-column prop="createTime" label="创建时间"  />
          <el-table-column label="操作" >

            <template #default="scope">
              <el-button
                  type="primary"
                  @click="handleEdit(scope.row)"
                  icon="Edit"
                  text
              >
                编辑
              </el-button>

              <el-button
                  type="danger"
                  :loading="scope.row.deleteLoading"
                  @click="handleDelete(scope.$index, scope.row)"
                  icon="Delete"
                  text
              >
                删除
              </el-button>

              <el-dropdown size="small" @command="(command) => handleCommand(command, scope.row)">
                <span class="el-dropdown-link">
                  <el-button
                      type="info"
                      :loading="scope.row.deleteLoading"
                      icon="MoreFilled"
                      text
                  >
                    更多
                  </el-button>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item command="2" icon="PictureFilled">产品系列图片</el-dropdown-item>
                  </el-dropdown-menu>
                </template>

              </el-dropdown>

            </template>

          </el-table-column>
        </el-table>

        <div style="width: 100%;display: flex;justify-content: right">
          <el-pagination
              background
              layout="prev, pager, next"
              :total="total"
              v-model:current-page="page"
              :page-count="pages"
              @current-change="getList"
          />
        </div>


        <el-dialog
            :title="title"
            v-model="open"
            @close="this.form = {}"
            style="width: 40%;"
        >
          <el-form :model="form" label-width="120px">
            <el-form-item label="产品名称">
              <el-input v-model="form.productName" style="width: 90%" maxlength="20" show-word-limit/>
            </el-form-item>

            <el-form-item label="产品简介">
              <el-input v-model="form.productBrief" style="width: 90%" maxlength="20" show-word-limit/>
            </el-form-item>

            <el-row>
              <el-col span="5">
                <el-form-item label="爆款">
                  <el-switch v-model="form.isHot" />
                </el-form-item>
              </el-col>
              <el-col span="5">
                <el-form-item label="新品">
                  <el-switch v-model="form.isNew" />
                </el-form-item>
              </el-col>
              <el-col span="5">
                <el-form-item label="畅销">
                  <el-switch v-model="form.isSellWell" />
                </el-form-item>
              </el-col>

            </el-row>

            <el-row>
              <el-col span="10">
                <el-form-item label="展示类型">
                  <el-select v-model="form.productShowTypeId" class="m-2" placeholder="选择展示类型" clearable>
                    <el-option
                        v-for="item in productShowTypeList"
                        :key="item.id"
                        :label="item.typeName"
                        :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col span="10">
                <el-col span="5">
                  <el-form-item label="排序">
                    <el-input-number v-model="form.sort" :min="1" />
                  </el-form-item>
                </el-col>
              </el-col>

            </el-row>

            <el-form-item label="产品尺寸">
              <el-input v-model="form.productSize" style="width:80%;"/>
            </el-form-item>

            <el-form-item label="产品重量">
              <el-input v-model="form.productWeight" style="width:80%;"/>
            </el-form-item>

            <el-form-item label="游戏内容">
              <el-input v-model="form.productContentSum" style="width:80%;"/>
            </el-form-item>

            <el-form-item label="体验人数">
              <el-input v-model="form.productCapacity" style="width:80%;"/>
            </el-form-item>

            <el-form-item label="时翻座率">
              <el-input v-model="form.productTurnover" style="width:80%;"/>
            </el-form-item>

            <!--            <el-row>-->
            <!--              <el-col :span="5">-->
            <!--                <el-form-item label="排序">-->
            <!--                  &lt;!&ndash;                  <el-input v-model="form.newsBrief" style="width: 100%" maxlength="20"/>&ndash;&gt;-->
            <!--                  <el-input-number v-model="form.sort" :min="1" />-->
            <!--                </el-form-item>-->
            <!--              </el-col>-->

            <!--              <el-col :span="8">-->
            <!--                <el-form-item label="SEO 标签">-->
            <!--                  <el-input v-model="form.sampleLabel" style="width: 100%" maxlength="20" show-word-limit/>-->
            <!--                </el-form-item>-->
            <!--              </el-col>-->

            <!--            </el-row>-->



            <!--            <el-form-item label="内容" id="aaab">-->
            <!--              &lt;!&ndash;              <el-input v-model="form.newsContent" style="width: 70%" />&ndash;&gt;-->
            <!--              <vue-ueditor-wrap v-model="form.sampleContent" :config="editorConfig" editor-id="editor01" style="z-index: 1000;width: 90%" ></vue-ueditor-wrap>-->
            <!--            </el-form-item>-->
          </el-form>

          <template #footer>
            <span class="dialog-footer">
              <el-button @click="cancel">取消</el-button>
              <el-button type="primary" :loading="loading" @click="confirm">提交</el-button>
            </span>
          </template>

        </el-dialog>

        <!-- 首页图片弹框 -->
        <el-dialog
            :title="title"
            v-model="openHomeImg"
            @close="closeCover"
            style="width: 40%;"
            top="6vh"
        >
          <el-form :model="form" label-width="120px" v-loading="coverLoading">
            <el-form-item label="当前图片" v-if="form.homeImgUrl != null">
              <el-image
                  style="width: 100px; height: 100px;display: inline-block;margin-right: 10px;"
                  :src="form.homeImgUrl + '?t=' + Date.now()"
                  :preview-src-list="[form.homeImgUrl + '?t=' + Date.now()]"
                  fit="fill"
                  :lazy="true"
              >
                <template v-slot:placeholder>
                  <div class="image-slot" style="font-size: small">加载中</div>
                </template>

                <template #error>
                  <div class="image-slot">
                    <el-icon><icon-picture /></el-icon>
                  </div>
                </template>
              </el-image>
            </el-form-item>

            <el-form-item label="上传图片">

              <el-upload
                  ref="uploadHomeImgRef"
                  drag
                  :on-exceed="handleExceed"
                  :on-success="handleCoverSuccess"
                  :auto-upload="false"
                  accept=".jpg,.jpeg,.png"
                  :file-list="coverFileList"
                  :data="news"
                  :limit="1"
                  action="/website-api/product/uploadHomeImg"
                  :headers="headers"
                  style="width: 50%"
                  :list-type="'picture'"
              >
                <el-icon class="el-icon--upload"><upload-filled /></el-icon>
                <div class="el-upload__text">
                  拖拽或 <em>上传</em> 文件
                </div>
                <template #tip>
                  <div class="el-upload__tip">
                    上传 jpg/png 图片，不大于 10Mb
                  </div>
                </template>
              </el-upload>

            </el-form-item>


          </el-form>

          <template #footer>
            <span class="dialog-footer">
              <el-button @click="cancel">取消</el-button>
              <el-button type="primary" @click="confirmCover">提交</el-button>
            </span>
          </template>

        </el-dialog>

        <!-- 产品中心图片弹框 -->
        <el-dialog
            :title="title"
            v-model="a"
            @close="closeCover"
            style="width: 40%;"
            top="6vh"
        >
          <el-form :model="form" label-width="120px" v-loading="coverLoading">
            <el-form-item label="当前图片" v-if="form.seriesImgUrl != null">
              <el-image
                  style="width: 100px; height: 100px;display: inline-block;margin-right: 10px;"
                  :src="form.seriesImgUrl + '?t=' + Date.now()"
                  :preview-src-list="[form.seriesImgUrl + '?t=' + Date.now()]"
                  fit="fill"
                  :lazy="true"
              >
                <template v-slot:placeholder>
                  <div class="image-slot" style="font-size: small">加载中</div>
                </template>

                <template #error>
                  <div class="image-slot">
                    <el-icon><icon-picture /></el-icon>
                  </div>
                </template>
              </el-image>
            </el-form-item>

            <el-form-item label="上传图片">

              <el-upload
                  ref="uploadHomeImgRef"
                  drag
                  :on-exceed="handleExceed"
                  :on-success="handleCoverSuccess"
                  :auto-upload="false"
                  accept=".jpg,.jpeg,.png"
                  :file-list="coverFileList"
                  :data="news"
                  :limit="1"
                  action="/website-api/product/uploadSeriesImg"
                  :headers="headers"
                  style="width: 50%"
                  :list-type="'picture'"
              >
                <el-icon class="el-icon--upload"><upload-filled /></el-icon>
                <div class="el-upload__text">
                  拖拽或 <em>上传</em> 文件
                </div>
                <template #tip>
                  <div class="el-upload__tip">
                    上传 jpg/png 图片，不大于 10Mb
                  </div>
                </template>
              </el-upload>

            </el-form-item>


          </el-form>

          <template #footer>
            <span class="dialog-footer">
              <el-button @click="cancel">取消</el-button>
              <el-button type="primary" @click="confirmCover">提交</el-button>
            </span>
          </template>

        </el-dialog>



        <!-- 封面弹框 -->
        <el-dialog
            :title="title"
            v-model="openSeriesImg"
            @close="closeSeries"
            style="width: 40%;"
            top="6vh"
        >
          <el-form :model="form" label-width="120px" v-loading="coverLoading">
            <el-row>
              <el-col span="9">
                <el-form-item label="当前封面" v-if="form.seriesImgUrl != null">
                  <el-image
                      style="width: 100px; height: 100px;display: inline-block;margin-right: 10px;"
                      :src="form.seriesImgUrl + '?t=' + Date.now()"
                      :preview-src-list="[form.seriesImgUrl + '?t=' + Date.now()]"
                      fit="fill"
                      :lazy="true"
                  >
                    <template v-slot:placeholder>
                      <div class="image-slot" style="font-size: small">加载中</div>
                    </template>

                    <template #error>
                      <div class="image-slot">
                        <el-icon><icon-picture /></el-icon>
                      </div>
                    </template>
                  </el-image>
                </el-form-item>
              </el-col>

              <el-col span="9">
                <el-form-item label="大图封面" v-if="form.bigSeriesImgUrl != null">
                  <el-image
                      style="width: 100px; height: 100px;display: inline-block;margin-right: 10px;"
                      :src="form.bigSeriesImgUrl + '?t=' + Date.now()"
                      :preview-src-list="[form.bigSeriesImgUrl + '?t=' + Date.now()]"
                      fit="fill"
                      :lazy="true"
                  >
                    <template v-slot:placeholder>
                      <div class="image-slot" style="font-size: small">加载中</div>
                    </template>

                    <template #error>
                      <div class="image-slot">
                        <el-icon><icon-picture /></el-icon>
                      </div>
                    </template>
                  </el-image>
                </el-form-item>
              </el-col>

            </el-row>


            <el-form-item label="上传封面">

              <el-upload
                  ref="uploadSeriesImgRef"
                  drag
                  :on-exceed="handleExceed"
                  :on-success="handleSeriesImgSuccess"
                  accept=".jpg,.jpeg,.png"
                  :file-list="coverFileList"
                  :data="news"
                  :limit="1"
                  action="/website-api/product/uploadSeriesImg"
                  :headers="headers"
                  style="width: 50%"
                  :list-type="'picture'"
              >
                <el-icon class="el-icon--upload"><upload-filled /></el-icon>
                <div class="el-upload__text">
                  拖拽或 <em>上传</em> 文件
                </div>
                <template #tip>
                  <div class="el-upload__tip">
                    建议尺寸: 383 x 441
                  </div>
                </template>
              </el-upload>

            </el-form-item>

            <el-form-item label="大图封面">

              <el-upload
                  ref="uploadBigSeriesImgRef"
                  drag
                  :on-exceed="handleExceed"
                  :on-success="handleSeriesImgSuccess"
                  accept=".jpg,.jpeg,.png"
                  :file-list="bigCoverFileList"
                  :data="news"
                  :limit="1"
                  action="/website-api/product/uploadBigSeriesImg"
                  :headers="headers"
                  style="width: 50%"
                  :list-type="'picture'"
              >
                <el-icon class="el-icon--upload"><upload-filled /></el-icon>
                <div class="el-upload__text">
                  拖拽或 <em>上传</em> 文件
                </div>
                <template #tip>
                  <div class="el-upload__tip">
                    建议尺寸: 767 x 441
                  </div>
                </template>
              </el-upload>

            </el-form-item>


          </el-form>

          <!--          <template #footer>-->
          <!--            <span class="dialog-footer">-->
          <!--              <el-button @click="cancel">取消</el-button>-->
          <!--              <el-button type="primary" @click="confirmCover">提交</el-button>-->
          <!--            </span>-->
          <!--          </template>-->

        </el-dialog>


        <!-- 详情顶部图片弹框 -->
        <el-dialog
            :title="title"
            v-model="openDetailTopImg"
            @close="closeDetailTopImg"
            style="width: 40%;"
            top="6vh"
        >
          <el-form :model="form" label-width="120px" v-loading="coverLoading">
            <el-form-item label="当前图片" v-if="form.detailTopImgUrl != null">
              <el-image
                  style="width: 100px; height: 100px;display: inline-block;margin-right: 10px;"
                  :src="form.detailTopImgUrl + '?t=' + Date.now()"
                  :preview-src-list="[form.detailTopImgUrl + '?t=' + Date.now()]"
                  fit="fill"
                  :lazy="true"
              >
                <template v-slot:placeholder>
                  <div class="image-slot" style="font-size: small">加载中</div>
                </template>

                <template #error>
                  <div class="image-slot">
                    <el-icon><icon-picture /></el-icon>
                  </div>
                </template>
              </el-image>
            </el-form-item>

            <el-form-item label="上传图片">

              <el-upload
                  ref="uploadDetailTopImgRef"
                  drag
                  :on-exceed="handleExceed"
                  :on-success="handleDetailTopImgSuccess"
                  :auto-upload="false"
                  accept=".jpg,.jpeg,.png"
                  :file-list="coverFileList"
                  :data="news"
                  :limit="1"
                  action="/website-api/product/uploadDetailTopImg"
                  :headers="headers"
                  style="width: 50%"
                  :list-type="'picture'"
              >
                <el-icon class="el-icon--upload"><upload-filled /></el-icon>
                <div class="el-upload__text">
                  拖拽或 <em>上传</em> 文件
                </div>
                <template #tip>
                  <div class="el-upload__tip">
                    上传 jpg/png 图片，不大于 10Mb
                  </div>
                </template>
              </el-upload>

            </el-form-item>


          </el-form>

          <template #footer>
            <span class="dialog-footer">
              <el-button @click="cancel">取消</el-button>
              <el-button type="primary" @click="confirmDetailTopImg">提交</el-button>
            </span>
          </template>

        </el-dialog>

        <!-- 详情视频封面弹框 -->
        <el-dialog
            :title="title"
            v-model="openVideoCover"
            @close="closeVideoImg"
            style="width: 40%;"
            top="6vh"
        >
          <el-form :model="form" label-width="120px" v-loading="coverLoading">
            <el-form-item label="当前图片" v-if="form.videoImgUrl != null">
              <el-image
                  style="width: 100px; height: 100px;display: inline-block;margin-right: 10px;"
                  :src="form.videoImgUrl + '?t=' + Date.now()"
                  :preview-src-list="[form.videoImgUrl + '?t=' + Date.now()]"
                  fit="fill"
                  :lazy="true"
              >
                <template v-slot:placeholder>
                  <div class="image-slot" style="font-size: small">加载中</div>
                </template>

                <template #error>
                  <div class="image-slot">
                    <el-icon><icon-picture /></el-icon>
                  </div>
                </template>
              </el-image>
            </el-form-item>

            <el-form-item label="上传图片">

              <el-upload
                  ref="uploadVideoImgRef"
                  drag
                  :on-exceed="handleExceed"
                  :on-success="handleVideoImgSuccess"
                  :auto-upload="false"
                  accept=".jpg,.jpeg,.png,.gif"
                  :file-list="coverFileList"
                  :data="news"
                  :limit="1"
                  action="/website-api/product/uploadVideoImg"
                  :headers="headers"
                  style="width: 50%"
                  :list-type="'picture'"
              >
                <el-icon class="el-icon--upload"><upload-filled /></el-icon>
                <div class="el-upload__text">
                  拖拽或 <em>上传</em> 文件
                </div>
                <template #tip>
                  <div class="el-upload__tip">
                    上传 jpg/png 图片，不大于 10Mb
                  </div>
                </template>
              </el-upload>

            </el-form-item>


          </el-form>

          <template #footer>
            <span class="dialog-footer">
              <el-button @click="cancel">取消</el-button>
              <el-button type="primary" @click="confirmVideoImg">提交</el-button>
            </span>
          </template>

        </el-dialog>


        <!-- 详情视频弹框 -->
        <el-dialog
            :title="title"
            v-model="openVideo"
            @close="closeVideo"
            style="width: 40%;"
            top="6vh"
        >
          <el-form :model="form" label-width="120px" v-loading="coverLoading">
            <el-form-item label="当前视频" v-if="form.videoUrl != null">
              <video muted autoplay loop :src="form.videoUrl + '?t=' + Date.now()" style="width: 240px;height: 135px;"/>
            </el-form-item>

            <el-form-item label="上传视频">
              <el-upload
                  ref="uploadVideoRef"
                  drag
                  :on-exceed="handleExceed"
                  :on-success="handleVideoSuccess"
                  :auto-upload="false"
                  accept=".mp4"
                  :file-list="coverFileList"
                  :data="news"
                  :limit="1"
                  action="/website-api/product/uploadVideo"
                  :headers="headers"
                  style="width: 50%"
              >
                <el-icon class="el-icon--upload"><upload-filled /></el-icon>
                <div class="el-upload__text">
                  拖拽或 <em>上传</em> 文件
                </div>
                <template #tip>
                  <div class="el-upload__tip">
                    上传 jpg/png 图片，不大于 10Mb
                  </div>
                </template>
              </el-upload>
            </el-form-item>
          </el-form>

          <template #footer>
            <span class="dialog-footer">
              <el-button @click="cancel">取消</el-button>
              <el-button type="primary" @click="confirmVideo">提交</el-button>
            </span>
          </template>

        </el-dialog>

        <!-- 产品介绍图片弹框 -->
        <el-dialog
            :title="title"
            v-model="openIntroImg"
            @close="closeIntroImg"
            style="width: 40%;"
            top="6vh"
        >
          <el-form :model="form" label-width="120px" v-loading="coverLoading">
            <el-form-item label="当前图片" v-if="form.introImgUrl != null">
              <el-image v-for="(item, index) in form.introImgUrl" key="index"
                        style="width: 100px; height: 100px;display: inline-block;margin-right: 10px;"
                        :src="item + '?t=' + Date.now()"
                        :preview-src-list="[item + '?t=' + Date.now()]"
                        fit="fill"
                        :lazy="true"
              >
                <template v-slot:placeholder>
                  <div class="image-slot" style="font-size: small">加载中</div>
                </template>

                <template #error>
                  <div class="image-slot">
                    <el-icon><icon-picture /></el-icon>
                  </div>
                </template>
              </el-image>

            </el-form-item>

            <el-form-item label="上传图片">

              <el-upload
                  ref="uploadIntroImgRef"
                  drag
                  :on-exceed="handleExceed"
                  :on-success="handleIntroImgSuccess"
                  :on-change="handleIntroImgChange"
                  :auto-upload="false"
                  accept=".jpg,.jpeg,.png"
                  :file-list="coverFileList"
                  :data="news"
                  :limit="5"
                  multiple
                  action="/website-api/product/uploadIntroImg"
                  :headers="headers"
                  style="width: 50%"
                  :list-type="'picture'"
              >
                <el-icon class="el-icon--upload"><upload-filled /></el-icon>
                <div class="el-upload__text">
                  拖拽或 <em>上传</em> 文件
                </div>
                <template #tip>
                  <div class="el-upload__tip">
                    上传 jpg/png 图片，不大于 10Mb
                  </div>
                </template>
              </el-upload>

            </el-form-item>


          </el-form>

          <template #footer>
            <span class="dialog-footer">
              <el-button @click="cancel">取消</el-button>
              <el-button type="primary" @click="submitIntroImg">提交</el-button>
            </span>
          </template>

        </el-dialog>


        <!-- 热播图片弹框 -->
        <el-dialog
            :title="title"
            v-model="openHotImg"
            @close="closeHotImg"
            style="width: 40%;"
            top="6vh"
        >
          <el-form :model="form" label-width="120px" v-loading="coverLoading">
            <el-form-item label="当前图片" v-if="form.hotImgUrl != null">
              <el-image v-for="(item, index) in form.hotImgUrl" key="index"
                        style="width: 100px; height: 100px;display: inline-block;margin-right: 10px;margin-top: 10px;box-shadow: 0 2px 4px rgba(0, 0, 0, .52), 0 0 6px rgba(0, 0, 0, .14);border-radius: 10px;"
                        :src="item + '?t=' + Date.now()"
                        :preview-src-list="[item + '?t=' + Date.now()]"
                        fit="fill"
                        :lazy="true"
              >
                <template v-slot:placeholder>
                  <div class="image-slot" style="font-size: small">加载中</div>
                </template>

                <template #error>
                  <div class="image-slot">
                    <el-icon><icon-picture /></el-icon>
                  </div>
                </template>
              </el-image>

            </el-form-item>

            <el-form-item label="上传图片">

              <el-upload
                  ref="uploadHotImgUrlRef"
                  drag
                  :on-exceed="handleExceed"
                  :on-success="handleHotImgSuccess"
                  :on-change="handleIntroImgChange"
                  :auto-upload="false"
                  accept=".jpg,.jpeg,.png"
                  :file-list="coverFileList"
                  :data="news"
                  :limit="9"
                  multiple
                  action="/website-api/product/uploadHotImg"
                  :headers="headers"
                  style="width: 50%"
                  :list-type="'picture'"
              >
                <el-icon class="el-icon--upload"><upload-filled /></el-icon>
                <div class="el-upload__text">
                  拖拽或 <em>上传</em> 文件
                </div>
                <template #tip>
                  <div class="el-upload__tip">
                    上传 jpg/png 图片，不大于 10Mb
                  </div>
                </template>
              </el-upload>

            </el-form-item>


          </el-form>

          <template #footer>
            <span class="dialog-footer">
              <el-button @click="cancel">取消</el-button>
              <el-button type="primary" @click="submitHotImg">提交</el-button>
            </span>
          </template>

        </el-dialog>


        <!-- 落地案例弹框 -->
        <el-dialog
            :title="title"
            v-model="openLandingImg"
            @close="closeLandingImg"
            style="width: 40%;"
            top="6vh"
        >
          <el-form :model="form" label-width="120px" v-loading="coverLoading">
            <el-form-item label="当前图片" v-if="form.landingImgUrl != null">
              <el-image v-for="(item, index) in form.landingImgUrl" key="index"
                        style="width: 100px; height: 100px;display: inline-block;margin-right: 10px;margin-top: 10px"
                        :src="item + '?t=' + Date.now()"
                        :preview-src-list="[item + '?t=' + Date.now()]"
                        fit="fill"
                        :lazy="true"
              >
                <template v-slot:placeholder>
                  <div class="image-slot" style="font-size: small">加载中</div>
                </template>

                <template #error>
                  <div class="image-slot">
                    <el-icon><icon-picture /></el-icon>
                  </div>
                </template>
              </el-image>

            </el-form-item>

            <el-form-item label="上传图片">

              <el-upload
                  ref="uploadLandingImgRef"
                  drag
                  :on-exceed="handleExceed"
                  :on-success="handleLandingImgSuccess"
                  :on-change="handleIntroImgChange"
                  :auto-upload="false"
                  accept=".jpg,.jpeg,.png"
                  :file-list="coverFileList"
                  :data="news"
                  :limit="9"
                  multiple
                  action="/website-api/product/uploadLandingImg"
                  :headers="headers"
                  style="width: 50%"
                  :list-type="'picture'"
              >
                <el-icon class="el-icon--upload"><upload-filled /></el-icon>
                <div class="el-upload__text">
                  拖拽或 <em>上传</em> 文件
                </div>
                <template #tip>
                  <div class="el-upload__tip">
                    上传 jpg/png 图片，不大于 10Mb
                  </div>
                </template>
              </el-upload>

            </el-form-item>


          </el-form>

          <template #footer>
            <span class="dialog-footer">
              <el-button @click="cancel">取消</el-button>
              <el-button type="primary" @click="submitLandingImg">提交</el-button>
            </span>
          </template>

        </el-dialog>

        <!-- 爆款搭配图片弹框 -->
        <el-dialog
            :title="title"
            v-model="openPairImg"
            @close="closePairImg"
            style="width: 40%;"
            top="6vh"
        >
          <el-form :model="form" label-width="120px" v-loading="coverLoading">
            <el-form-item label="当前图片" v-if="form.pairImgUrl != null">
              <el-image
                  style="width: 100px; height: 100px;display: inline-block;margin-right: 10px;"
                  :src="form.pairImgUrl + '?t=' + Date.now()"
                  :preview-src-list="[form.pairImgUrl + '?t=' + Date.now()]"
                  fit="fill"
                  :lazy="true"
              >
                <template v-slot:placeholder>
                  <div class="image-slot" style="font-size: small">加载中</div>
                </template>

                <template #error>
                  <div class="image-slot">
                    <el-icon><icon-picture /></el-icon>
                  </div>
                </template>
              </el-image>
            </el-form-item>

            <el-form-item label="上传图片">

              <el-upload
                  ref="uploadPairImgRef"
                  drag
                  :on-exceed="handleExceed"
                  :on-success="handlePairImgSuccess"
                  :auto-upload="false"
                  accept=".jpg,.jpeg,.png,.gif"
                  :file-list="coverFileList"
                  :data="news"
                  :limit="1"
                  action="/website-api/product/uploadPairImg"
                  :headers="headers"
                  style="width: 50%"
                  :list-type="'picture'"
              >
                <el-icon class="el-icon--upload"><upload-filled /></el-icon>
                <div class="el-upload__text">
                  拖拽或 <em>上传</em> 文件
                </div>
                <template #tip>
                  <div class="el-upload__tip">
                    上传 jpg/png 图片，不大于 10Mb
                  </div>
                </template>
              </el-upload>

            </el-form-item>


          </el-form>

          <template #footer>
            <span class="dialog-footer">
              <el-button @click="cancel">取消</el-button>
              <el-button type="primary" @click="confirmPairimg">提交</el-button>
            </span>
          </template>

        </el-dialog>


      </div>
    </el-card>
  </div>
</template>

<script>
import {
  Document,
  Edit,
  Delete,
  Picture as IconPicture,
  UploadFilled,
  Search
} from '@element-plus/icons-vue'

import {page, saveOrUpdate, getById, deleteProduct,
  uploadCover, uploadIntroImg, uploadHotImg, uploadLandingImg} from "@/api/product";
import {list} from "@/api/productShowType";
import {ElMessage, ElMessageBox} from "element-plus";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "ProductSeries",
  components: {
    Document, IconPicture, UploadFilled, Search
  },
  data() {
    return {
      openLandingImg: false,
      openHotImg: false,
      openIntroImg: false,
      openPairImg: false,
      openVideo: false,
      openVideoCover: false,
      loading: false,
      productShowTypeList: [],
      bigCoverFileList: [],
      queryParam: {},
      page: 1,
      pageSize: 10,
      total: 0,
      pages: 1,
      headers: {},
      news: {},
      newsId: null,
      coverLoading: false,
      coverFileList: [],
      sort: null,
      isTop: false,
      deleteLoading: false,
      msg: '',
      newsList: [],
      open: false,
      openHomeImg: false,
      openSeriesImg: false,
      openDetailTopImg: false,
      title: '',
      form: {},
      editorConfig: {
        UEDITOR_HOME_URL: '/utf8-jsp/',
        serverUrl: '//localhost/website-api/editor/server',
        initialFrameWidth: '90%',
        initialFrameHeight: '400',
        autoHeightEnabled: false,
        zIndex: 2999,
        toolbars: [
          ['preview', '|', 'undo', 'redo', '|', 'bold',  'italic', 'underline', 'strikethrough', 'fontborder', 'forecolor', 'backcolor',  'formatmatch', 'blockquote', 'superscript', 'subscript', 'horizontal', '|', 'justifyleft', 'justifycenter', 'justifyright', 'justifyjustify', 'indent', '|', 'insertunorderedlist', 'insertorderedlist', 'insertframe', 'paragraph', 'fontfamily', 'fontsize'],
          ['imagenone', 'imageleft', 'imagecenter', 'imageright', 'attachment', 'time', 'date', 'simpleupload', 'insertvideo', 'music', 'inserttable', 'link', 'emotion', 'spechars', 'searchreplace', 'map', 'template']
        ]
      }
    }
  },
  created() {
    this.getList()
    list().then(resp => {
      this.productShowTypeList = resp.data.data
      let type = this.productShowTypeList.filter(type => type.id == 3)
      console.log(type)
    })
  },
  methods: {

    // 更多
    handleCommand(command, row) {
      const token = window.localStorage.getItem('token')
      const p = window.localStorage.getItem('p')
      this.headers = {
        Authorization: token,
        p: p
      }
      if (command === '1') {
        this.title = '上传首页图片'
        getById(row.id).then(resp => {
          this.form = resp.data.data
          this.news = {id: row.id}
          this.openHomeImg = true
        })
      } else if (command === '2') {
        this.title = '上传产品中心图片'
        getById(row.id).then(resp => {
          this.form = resp.data.data
          this.news = {id: row.id}
          this.openSeriesImg = true
        })
      } else if (command === '3') {
        this.title = '详情顶部图片'
        getById(row.id).then(resp => {
          this.form = resp.data.data
          this.news = {id: row.id}
          this.openDetailTopImg = true
        })
      } else if (command === '4') {
        this.title = '上传视频封面'
        getById(row.id).then(resp => {
          this.form = resp.data.data
          this.news = {id: row.id}
          this.openIntroImg = true
        })
      } else if (command === '5') {
        this.title = '上传视频'
        getById(row.id).then(resp => {
          this.form = resp.data.data
          this.news = {id: row.id}
          this.openVideo = true
        })
      } else if (command === '6') {
        this.title = '产品介绍图片'
        getById(row.id).then(resp => {
          let data = resp.data.data
          if (data.introImgUrl != null && data.introImgUrl.length > 0) {
            data.introImgUrl = data.introImgUrl.split(',')
          } else {
            data.introImgUrl = []
          }
          this.form = data
          this.news = {id: row.id}
          this.openIntroImg = true
        })
      } else if (command === '7') {
        this.title = '门店热播'
        getById(row.id).then(resp => {
          let data = resp.data.data
          if (data.hotImgUrl != null && data.hotImgUrl.length > 0) {
            data.hotImgUrl = data.hotImgUrl.split(',')
          } else {
            data.hotImgUrl = []
          }
          this.form = data
          this.news = {id: row.id}
          this.openHotImg = true
        })
      } else if (command === '8') {
        this.title = '落地案例'
        getById(row.id).then(resp => {
          let data = resp.data.data
          if (data.landingImgUrl != null && data.landingImgUrl.length > 0) {
            data.landingImgUrl = data.landingImgUrl.split(',')
          } else {
            data.landingImgUrl = []
          }
          this.form = data
          this.news = {id: row.id}
          this.openLandingImg = true
        })
      } else if (command === '9') {
        // 爆款搭配图片
        this.title = '爆款搭配图片'
        getById(row.id).then(resp => {
          this.form = resp.data.data
          this.news = {id: row.id}
          this.openPairImg = true
        })
      }

    },

    refresh(id) {
      getById(id).then(resp => {
        this.form = resp.data.data

      })
    },

    refreshHomeImg(id) {
      getById(id).then(resp => {
        this.form = resp.data.data
        this.news = {id: row.id}
        const token = window.localStorage.getItem('token')
        this.headers = {
          Authorization: token
        }
        this.openHomeImg = true
      })
    },

    handleExceed() {
      ElMessage({
        type: 'error',
        message: '超出上传数量限制',
        duration: "1000"
      })
    },

    getList() {
      this.queryParam.page = this.page
      this.queryParam.pageSize = this.pageSize
      page(this.queryParam).then(resp => {
        this.newsList = resp.data.data.records
        this.pages = resp.data.data.pages
      })
    },
    handleAdd() {
      this.title = '添加产品'
      this.open = true
    },
    handleEdit(row) {
      let newsId = row.id
      this.title = '修改产品'
      getById(newsId).then(resp => {
        console.log(resp)
        this.form = resp.data.data
        this.open = true
      })
    },
    handleDelete(index, row) {
      ElMessageBox.confirm(
          '确认删除产品 "' + row.productName + '"',
          '提示',
          {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning'
          }
      ).then(() => {
        row.deleteLoading = true
        deleteProduct(row.id).then(resp => {
          this.getList()
          row.deleteLoading = false
        })
      }).catch(() => {

      })

    },
    handleImg(row) {
      this.title = '上传封面'
      getById(row.id).then(resp => {
        this.form = resp.data.data
        this.news = {id: row.id}
        const token = window.localStorage.getItem('token')
        this.headers = {
          Authorization: token
        }
        this.openImg = true
      })

    },
    cancel() {
      this.open = false
      this.openImg = false
      this.openLandingImg = false
      this.openPairImg = false
      this.form = {}
    },
    confirm() {
      this.loading = true
      saveOrUpdate(this.form).then(resp => {
        this.getList()
        this.loading = false
        this.open = false
        this.form = {}

      })
    },

    beforeUploadCover(file) {
      let fd = new FormData()
      fd.append('file', file)
      fd.append('newsId', this.newsId)
      console.log('newsId '+ this.newsId)
      this.coverLoading = true
      uploadCover(fd).then(resp => {
        console.log(resp.data)
        this.coverLoading = false
      })
    },

    confirmCover() {
      this.$refs.uploadHomeImgRef.submit()
    },

    confirmDetailTopImg() {
      this.$refs.uploadDetailTopImgRef.submit();
    },

    confirmVideoImg() {
      this.$refs.uploadVideoImgRef.submit()
    },

    confirmPairimg() {
      this.$refs.uploadPairImgRef.submit();
    },

    confirmVideo() {
      this.$refs.uploadVideoRef.submit()
    },

    confirmIntroImg() {
      console.log(this.coverFileList[0].file)
      // this.$refs.uploadIntroImgRef.submit()
    },

    handleCoverSuccess() {
      this.openImg = false
      this.$refs.uploadHomeImgRef.clearFiles()
      this.getList()
    },

    handleDetailTopImgSuccess() {
      this.openDetailTopImg = false
      this.$refs.uploadDetailTopImgRef.clearFiles()
      this.getList()
    },

    handleVideoImgSuccess() {
      this.openVideoCover = false
      this.$refs.uploadVideoImgRef.clearFiles()
      ElMessage({
        type: 'success',
        message: '上传成功'
      })
      this.getList()
    },

    handlePairImgSuccess() {
      // this.openPairImg = false
      this.refresh(this.form.id)
      this.$refs.uploadPairImgRef.clearFiles()
      this.getList()
    },

    handleIntroImgSuccess() {
      this.openIntroImg = false
      this.$refs.uploadIntroImgRef.clearFiles()
      this.getList()
    },

    handleHotImgSuccess() {
      this.openHotImg = false
      this.$refs.uploadHotImgUrlRef.clearFiles()
      this.getList()
    },

    handleLandingImgSuccess() {
      this.openLandingImg = false
      this.$refs.uploadLandingImgRef.clearFiles()
      this.getList()
    },

    handleIntroImgChange(file, files) {
      this.coverFileList = files
    },

    submitIntroImg() {
      let fd = new FormData()
      this.coverFileList.forEach(file => {
        console.log('文件:',file.raw)
        fd.append('fileList', file.raw)
      })
      fd.append('id', this.form.id)
      console.log('蚕食:')
      console.log(fd)
      uploadIntroImg(fd).then(resp => {
        this.$refs.uploadIntroImgRef.clearFiles()
      })
    },

    submitHotImg() {
      let fd = new FormData()
      this.coverFileList.forEach(file => {
        fd.append('fileList', file.raw)
      })
      fd.append('id', this.form.id)
      uploadHotImg(fd).then(resp => {
        this.$refs.uploadHotImgUrlRef.clearFiles()
      })
    },

    submitLandingImg() {
      let fd = new FormData()
      this.coverFileList.forEach(file => {
        fd.append('fileList', file.raw)
      })
      fd.append('id', this.form.id)
      uploadLandingImg(fd).then(resp => {
        this.openLandingImg = false
        this.$refs.uploadLandingImgRef.clearFiles()
        this.getList()
      })
    },

    handleVideoSuccess() {
      this.getList()
      this.openVideo = false
      this.$refs.uploadVideoRef.clearFiles()
      ElMessage({
        type: 'success',
        message: '上传成功'
      })

    },

    handleSeriesImgSuccess() {
      // this.openImg = false
      this.refresh(this.form.id)
      this.$refs.uploadSeriesImgRef.clearFiles()
      this.$refs.uploadBigSeriesImgRef.clearFiles()
      this.getList()
    },

    closeCover() {
      this.$refs.uploadHomeImgRef.clearFiles()
      this.form = {}
    },

    closeSeries() {
      this.$refs.uploadSeriesImgRef.clearFiles()
      this.form = {}
    },

    closeDetailTopImg() {
      this.$refs.uploadDetailTopImgRef.clearFiles()
      this.form = {}
    },

    closeVideoImg() {
      this.$refs.uploadVideoImgRef.clearFiles()
      this.form = {}
    },

    closePairImg() {
      this.$refs.uploadPairImgRef.clearFiles()
      this.form = {}
    },

    closeVideo() {
      this.$refs.uploadVideoRef.clearFiles()
      this.form = {}
    },

    closeIntroImg() {
      this.$refs.uploadIntroImgRef.clearFiles()
      this.form = {}
    },

    closeHotImg() {
      this.$refs.uploadHotImgUrlRef.clearFiles()
      this.form = {}
    },

    closeLandingImg() {
      this.$refs.uploadLandingImgRef.clearFiles()
      this.form = {}
    },

    clearSeriesImg() {
      this.$refs.uploadSeriesImgRef.clearFiles()
    },

    search() {
      console.log(this.queryParam)
    }

  }
}
</script>

<style scoped>

.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--el-fill-color-light);
  color: var(--el-text-color-secondary);
  font-size: 30px;
}

</style>