import request from "@/util/request";

export function list() {
    return request({
        url: '/user/list',
        method: 'get'
    })
}

export function login(data) {
    return request({
        url: '/user/login',
        method: 'post',
        data: data
    })
}

export function loginUser() {
    return request({
        url: '/user/loginUser',
        method: 'get'
    })
}

export function updateProfile(data) {
    return request({
        url: '/user/updateProfile',
        method: 'post',
        data: data
    })
}

export function resetPassword(data) {
    return request({
        url: '/user/resetPassword',
        method: 'post',
        data: data
    })
}

export function saveOrUpdate(data) {
    return request({
        url: '/user/saveOrUpdate',
        method: 'post',
        data: data
    })
}

export function getById(id) {
    return request({
        url: '/user/getById',
        method: 'get',
        params: {
            id: id
        }
    })
}

export function deleteUser(id) {
    return request({
        url: '/user/delete',
        method: 'delete',
        params: {
            id: id
        }
    })
}