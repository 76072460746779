<!-- 新闻中心 -->

<template>
  <div>
    <el-card style="width: 50%;">
      <div>
        <div style="width: 100%;display: flex;justify-content: space-between">
          <span>
            <el-icon><Basketball /></el-icon>
            <strong>&nbsp;游戏管理</strong>
          </span>

<!--          <span>-->
<!--            <el-input style="width: 72%" v-model="queryParam.schemeTitle" placeholder="搜索" prefix-icon="Search" @keyup.enter="getList" />-->
<!--            <el-button style="width: 25%;margin-left: 3%" @click="getList">搜索</el-button>-->
<!--          </span>-->

        </div>

        <el-table stripe :data="newsList" style="width: 100%;min-height: 550px;margin-top: 2%;" empty-text="暂无数据">
          <el-table-column prop="customerName" label="姓名" width="150" />
          <el-table-column prop="customerTel" label="电话" width="150" />
          <el-table-column prop="createTime" label="咨询时间"  />
          <el-table-column label="操作" >

            <template #default="scope">


              <el-button
                  type="danger"
                  :loading="scope.row.deleteLoading"
                  @click="handleDelete(scope.$index, scope.row)"
                  icon="Delete"
              >
                删除
              </el-button>

            </template>

          </el-table-column>
        </el-table>

        <div style="width: 100%;display: flex;justify-content: right">
          <el-pagination
              background
              layout="prev, pager, next"
              :total="total"
              v-model:current-page="page"
              :page-count="pages"
              @current-change="getList"
          />
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import {
  Document,
  Edit,
  Delete,
  Picture as IconPicture,
  UploadFilled,
  Search
} from '@element-plus/icons-vue'

import {list, saveOrUpdate, getById, deleteNews, uploadCover} from "@/api/customer";
import {ElMessage, ElMessageBox} from "element-plus";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Customer",
  components: {
    Document, IconPicture, UploadFilled, Search
  },
  data() {
    return {
      queryParam: {},
      page: 1,
      pageSize: 10,
      total: 0,
      pages: 1,
      headers: {},
      news: {},
      newsId: null,
      coverLoading: false,
      coverFileList: [],
      sort: null,
      isTop: false,
      deleteLoading: false,
      msg: '',
      newsList: [],
      open: false,
      openImg: false,
      title: '',
      form: {},
      editorConfig: {
        UEDITOR_HOME_URL: '/utf8-jsp/',
        serverUrl: '//localhost/website-api/editor/server',
        initialFrameWidth: '90%',
        initialFrameHeight: '400',
        autoHeightEnabled: false,
        zIndex: 2999,
        toolbars: [
          ['preview', '|', 'undo', 'redo', '|', 'bold',  'italic', 'underline', 'strikethrough', 'fontborder', 'forecolor', 'backcolor',  'formatmatch', 'blockquote', 'superscript', 'subscript', 'horizontal', '|', 'justifyleft', 'justifycenter', 'justifyright', 'justifyjustify', 'indent', '|', 'insertunorderedlist', 'insertorderedlist', 'insertframe', 'paragraph', 'fontfamily', 'fontsize'],
          ['imagenone', 'imageleft', 'imagecenter', 'imageright', 'attachment', 'time', 'date', 'simpleupload', 'insertvideo', 'music', 'inserttable', 'link', 'emotion', 'spechars', 'searchreplace', 'map', 'template']
        ]
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    handleExceed() {
      ElMessage({
        type: 'error',
        message: '新闻封面只能上传一张',
        duration: "1000"
      })
    },

    getList() {
      this.queryParam.page = this.page
      this.queryParam.pageSize = this.pageSize
      list(this.queryParam).then(resp => {
        this.newsList = resp.data.data.records
        this.pages = resp.data.data.pages
      })
    },
    handleAdd() {
      this.title = '添加新闻'
      this.open = true
    },
    handleEdit(row) {
      let newsId = row.id
      this.title = '修改新闻'
      getById(newsId).then(resp => {
        console.log(resp)
        this.form = resp.data.data
        this.open = true
      })
    },
    handleDelete(index, row) {
      ElMessageBox.confirm(
          '确认删除 "' + row.customerName + '"',
          '提示',
          {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning'
          }
      ).then(() => {
        row.deleteLoading = true
        deleteNews(row.id).then(resp => {
          this.getList()
          row.deleteLoading = false
        })
      }).catch(() => {

      })

    },
    handleImg(row) {
      this.title = '上传封面'
      getById(row.id).then(resp => {
        this.form = resp.data.data
        this.news = {id: row.id}
        const token = window.localStorage.getItem('token')
        this.headers = {
          Authorization: token
        }
        this.openImg = true
      })

    },
    cancel() {
      this.open = false
      this.openImg = false
      this.form = {}
    },
    confirm() {
      saveOrUpdate(this.form).then(resp => {
        this.getList()
        this.open = false
        this.form = {}

      })
    },

    beforeUploadCover(file) {
      let fd = new FormData()
      fd.append('file', file)
      fd.append('newsId', this.newsId)
      console.log('newsId '+ this.newsId)
      this.coverLoading = true
      uploadCover(fd).then(resp => {
        console.log(resp.data)
        this.coverLoading = false
      })
    },

    confirmCover() {
      this.$refs.uploadCoverRef.submit()
    },

    handleCoverSuccess() {
      this.openImg = false
      this.$refs.uploadCoverRef.clearFiles()
      this.getList()
    },

    closeCover() {
      this.$refs.uploadCoverRef.clearFiles()
      this.form = {}
    },

    search() {
      console.log(this.queryParam)
    }

  }
}
</script>

<style scoped>

.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--el-fill-color-light);
  color: var(--el-text-color-secondary);
  font-size: 30px;
}

</style>