// 知识接口

import request from "@/util/request";

export function list(param) {
    return request({
        url: '/customer',
        method: 'get',
        params: param
    })
}

export function saveOrUpdate(news) {
    return request({
        url: '/customer',
        method: 'post',
        data: news
    })
}

export function getById(newsId) {
    return request({
        url: '/customer/getById/' + newsId,
        method: 'get'
    })
}

export function deleteNews(newsId) {
    return request({
        url: '/customer/delete',
        method: 'delete',
        params: {
            id: newsId
        }
    })
}

export function uploadCover(data) {
    return request({
        url: '/customer/uploadCover',
        method: 'post',
        data: data
    })
}

export function listNotice() {
    return request({
        url: '/notice/list',
        method: 'get'
    })
}

export function getNotice(id) {
    return request({
        url: '/notice/get',
        method: 'get',
        params: {id}
    })
}

export function deleteNotice(id) {
    return request({
        url: '/notice/delete',
        method: 'delete',
        params: {id}
    })
}

export function addNotice(data) {
    return request({
        url: '/notice/add',
        method: 'post',
        data: data
    })
}