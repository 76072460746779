<!-- 新闻中心 -->

<template>
  <div>
    <el-card>
      <div>
        <div style="width: 100%;display: flex;justify-content: space-between">
          <span>
            <el-icon><Opportunity /></el-icon>
            <strong>&nbsp;加盟方案</strong>
          </span>

          <span>
            <el-input style="width: 72%" v-model="queryParam.schemeTitle" placeholder="搜索" prefix-icon="Search" @keyup.enter="getList" />
            <el-button style="width: 25%;margin-left: 3%" @click="getList">搜索</el-button>
          </span>

        </div>


        <div style="margin: 10px;">
          <el-button type="primary" icon="Plus" @click="handleAdd">新增</el-button>
        </div>

        <el-table stripe :data="newsList" style="width: 100%;min-height: 550px;" empty-text="暂无数据">
          <el-table-column prop="sort" label="序号" width="100" />
          <el-table-column prop="schemeTitle" label="标题"  />
          <el-table-column prop="schemeBrief" label="简介"  />
          <el-table-column prop="schemeLabel" label="SEO 标签"  />
          <el-table-column prop="createTime" label="创建时间"  />
          <el-table-column label="操作" >

            <template #default="scope">
              <el-button
                  type="primary"
                  @click="handleEdit(scope.row)"
                  icon="Edit"
              >
                编辑
              </el-button>

              <el-button
                  @click="handleImg(scope.row)"
                  icon="Picture"
              >
                封面
              </el-button>

              <el-button
                  type="danger"
                  :loading="scope.row.deleteLoading"
                  @click="handleDelete(scope.$index, scope.row)"
                  icon="Delete"
              >
                删除
              </el-button>

            </template>

          </el-table-column>
        </el-table>

        <div style="width: 100%;display: flex;justify-content: right">
          <el-pagination
              background
              layout="prev, pager, next"
              :total="total"
              v-model:current-page="page"
              :page-count="pages"
              @current-change="getList"
          />
        </div>


        <el-dialog
            :title="title"
            v-model="open"
            @close="this.form = {}"
            style="width: 90%;"
            top="3vh"
            :close-on-click-modal="false"
        >
          <el-form :model="form" label-width="120px">
            <el-form-item label="标题">
              <el-input v-model="form.schemeTitle" style="width: 50%" />
            </el-form-item>

            <el-form-item label="简介">
              <el-input v-model="form.schemeBrief" style="width: 50%" />
            </el-form-item>

            <el-row>
              <!--              <el-col :span="3">-->
              <!--                <el-form-item label="放置首页">-->
              <!--                  &lt;!&ndash;                  <el-checkbox v-model="isTop" label="置顶" size="large" />&ndash;&gt;-->
              <!--                  <el-switch v-model="form.isTop" disabled />-->
              <!--                </el-form-item>-->
              <!--              </el-col>-->
              <el-col :span="5">
                <el-form-item label="排序">
                  <!--                  <el-input v-model="form.newsBrief" style="width: 100%" maxlength="20"/>-->
                  <el-input-number v-model="form.sort" :min="1" />
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item label="SEO 标签">
                  <el-input v-model="form.schemeLabel" style="width: 100%" maxlength="20" show-word-limit/>
                </el-form-item>
              </el-col>

            </el-row>



            <el-form-item label="内容" id="aaab">
              <!--              <el-input v-model="form.newsContent" style="width: 70%" />-->
              <vue-ueditor-wrap v-model="form.schemeContent" :config="editorConfig" editor-id="editor01" style="z-index: 1000;width: 90%" ></vue-ueditor-wrap>
            </el-form-item>
          </el-form>

          <template #footer>
            <span class="dialog-footer">
              <el-button @click="cancel">取消</el-button>
              <el-button type="primary" @click="confirm">提交</el-button>
            </span>
          </template>

        </el-dialog>

        <!-- 封面弹框 -->
        <el-dialog
            :title="title"
            v-model="openImg"
            @close="closeCover"
            style="width: 40%;"
            top="6vh"
        >
          <el-form :model="form" label-width="120px" v-loading="coverLoading">
            <el-form-item label="当前封面" v-if="form.imgUrl != null">
              <el-image
                  style="width: 100px; height: 100px;display: inline-block;margin-right: 10px;"
                  :src="form.imgUrl + '?t=' + Date.now()"
                  :preview-src-list="[form.imgUrl + '?t=' + Date.now()]"
                  fit="fill"
                  :lazy="true"
              >
                <template v-slot:placeholder>
                  <div class="image-slot" style="font-size: small">加载中</div>
                </template>

                <template #error>
                  <div class="image-slot">
                    <el-icon><icon-picture /></el-icon>
                  </div>
                </template>
              </el-image>
            </el-form-item>

            <el-form-item label="上传封面">

              <el-upload
                  ref="uploadCoverRef"
                  drag
                  :on-exceed="handleExceed"
                  :on-success="handleCoverSuccess"
                  :auto-upload="false"
                  accept=".jpg,.jpeg,.png"
                  :file-list="coverFileList"
                  :data="news"
                  :limit="1"
                  action="/website-api/scheme/uploadCover"
                  :headers="headers"
                  style="width: 50%"
                  :list-type="'picture'"
              >
                <el-icon class="el-icon--upload"><upload-filled /></el-icon>
                <div class="el-upload__text">
                  拖拽或 <em>上传</em> 文件
                </div>
                <template #tip>
                  <div class="el-upload__tip">
                    建议尺寸: 375 x 250
                  </div>
                </template>
              </el-upload>

            </el-form-item>


          </el-form>

          <template #footer>
            <span class="dialog-footer">
              <el-button @click="cancel">取消</el-button>
              <el-button type="primary" @click="confirmCover">提交</el-button>
            </span>
          </template>

        </el-dialog>

      </div>
    </el-card>
  </div>
</template>

<script>
import {
  Document,
  Edit,
  Delete,
  Picture as IconPicture,
  UploadFilled,
  Search
} from '@element-plus/icons-vue'

import {list, saveOrUpdate, getById, deleteNews, uploadCover} from "@/api/scheme";
import {ElMessage, ElMessageBox} from "element-plus";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Scheme",
  components: {
    Document, IconPicture, UploadFilled, Search
  },
  data() {
    return {
      queryParam: {},
      page: 1,
      pageSize: 10,
      total: 0,
      pages: 1,
      headers: {},
      news: {},
      newsId: null,
      coverLoading: false,
      coverFileList: [],
      sort: null,
      isTop: false,
      deleteLoading: false,
      msg: '',
      newsList: [],
      open: false,
      openImg: false,
      title: '',
      form: {},
      editorConfig: {
        UEDITOR_HOME_URL: '/dist/utf8-jsp/',
        serverUrl: 'http://120.48.133.253:80/website-api/editor/server',
        initialFrameWidth: '90%',
        initialFrameHeight: '400',
        autoHeightEnabled: false,
        zIndex: 2999,
        toolbars: [
          ['preview', '|', 'undo', 'redo', '|', 'bold',  'italic', 'underline', 'strikethrough', 'fontborder', 'forecolor', 'backcolor',  'formatmatch', 'blockquote', 'superscript', 'subscript', 'horizontal', '|', 'justifyleft', 'justifycenter', 'justifyright', 'justifyjustify', 'indent', '|', 'insertunorderedlist', 'insertorderedlist', 'insertframe', 'lineheight', 'paragraph', 'fontfamily', 'fontsize'],
          ['imagenone', 'imageleft', 'imagecenter', 'imageright', 'attachment', 'time', 'date', 'simpleupload', 'insertimage', 'insertvideo', 'music', 'inserttable', 'link', 'emotion', 'spechars', 'searchreplace', 'map', 'template']
        ]
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    handleExceed() {
      ElMessage({
        type: 'error',
        message: '新闻封面只能上传一张',
        duration: "1000"
      })
    },

    getList() {
      this.queryParam.page = this.page
      this.queryParam.pageSize = this.pageSize
      list(this.queryParam).then(resp => {
        this.newsList = resp.data.data.records
        this.pages = resp.data.data.pages
      })
    },
    handleAdd() {
      this.title = '添加新闻'
      this.open = true
    },
    handleEdit(row) {
      let newsId = row.id
      this.title = '修改新闻'
      getById(newsId).then(resp => {
        console.log(resp)
        this.form = resp.data.data
        this.open = true
      })
    },
    handleDelete(index, row) {
      ElMessageBox.confirm(
          '确认删除加盟方案 "' + row.schemeTitle + '"',
          '提示',
          {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning'
          }
      ).then(() => {
        row.deleteLoading = true
        deleteNews(row.id).then(resp => {
          this.getList()
          row.deleteLoading = false
        })
      }).catch(() => {

      })

    },
    handleImg(row) {
      this.title = '上传封面'
      getById(row.id).then(resp => {
        this.form = resp.data.data
        this.news = {id: row.id}
        const token = window.localStorage.getItem('token')
        const p = window.localStorage.getItem('p')
        this.headers = {
          Authorization: token,
          p: p
        }
        this.openImg = true
      })

    },
    cancel() {
      this.open = false
      this.openImg = false
      this.form = {}
    },
    confirm() {
      saveOrUpdate(this.form).then(resp => {
        this.getList()
        this.open = false
        this.form = {}

      })
    },

    beforeUploadCover(file) {
      let fd = new FormData()
      fd.append('file', file)
      fd.append('newsId', this.newsId)
      console.log('newsId '+ this.newsId)
      this.coverLoading = true
      uploadCover(fd).then(resp => {
        console.log(resp.data)
        this.coverLoading = false
      })
    },

    confirmCover() {
      this.$refs.uploadCoverRef.submit()
    },

    handleCoverSuccess() {
      this.openImg = false
      this.$refs.uploadCoverRef.clearFiles()
      this.getList()
    },

    closeCover() {
      this.$refs.uploadCoverRef.clearFiles()
      this.form = {}
    },

    search() {
      console.log(this.queryParam)
    }

  }
}
</script>

<style scoped>

.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--el-fill-color-light);
  color: var(--el-text-color-secondary);
  font-size: 30px;
}

</style>