// 知识接口

import request from "@/util/request";

export function list(param) {
    return request({
        url: '/knowledge',
        method: 'get',
        params: param
    })
}

export function saveOrUpdate(news) {
    return request({
        url: '/knowledge',
        method: 'post',
        data: news
    })
}

export function getById(newsId) {
    return request({
        url: '/knowledge/getById/' + newsId,
        method: 'get'
    })
}

export function deleteNews(newsId) {
    return request({
        url: '/knowledge/delete',
        method: 'delete',
        params: {
            id: newsId
        }
    })
}

export function uploadCover(data) {
    return request({
        url: '/knowledge/uploadCover',
        method: 'post',
        data: data
    })
}