import axios from "axios";
import {ElMessage} from "element-plus";
import router from "@/router";
axios.defaults.baseURL='/website-api'
const service = axios.create({
    timeout: 3600000,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        'token': 'your token',
        'X-Requested-With': 'XMLHttpRequest',
    },
})

// 添加请求拦截器
service.interceptors.request.use(
    function (config) {
        let token = window.localStorage.getItem("token")
        let device = window.localStorage.getItem("p")
        if (token != null) {
            console.log(token)
            // config.headers["Authorization"] = token
            config.headers.Authorization=token
        }
        if (device != null) {
            config.headers.p = device
        }
        console.log(config);
        return config
    },
    function (error) {
        console.log(error)
        return Promise.reject(error)
    }
)

// 添加响应拦截器
service.interceptors.response.use(
    function (response) {

      let data = response.data;
      if (data.code !== '0') {
          ElMessage.error(data.msg)
          if (data.code === '100') {
              window.localStorage.removeItem("token")
              router.replace({path: '/login'})
          }
          return Promise.reject(data.msg)
      } else {
          return response;
      }
    },
    function (error) {
        console.log(error)
        return Promise.reject(error)
    }
)
export default service