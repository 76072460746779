<template>
<!--  <HelloWorld msg="Welcome to Your Vue.js App"/>-->
<!--  <Login/>-->
  <router-view/>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import Login from "@/components/Login";

export default {
  name: 'App',
  components: {
    // HelloWorld
  }
}
</script>

<style>

html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
  padding: 0;
  margin: 0;
  filter: grayscale(0%);
}
</style>
