<template>
  <div>
    <el-card style="width: 48%;height: 25rem;position: relative">
      <div style="height: 100%">
        <div style="width: 100%;display: flex;justify-content: space-between">
          <span>
            <el-icon><UserFilled /></el-icon>
            <strong>&nbsp;个人中心</strong>
          </span>
        </div>
        <el-tabs v-model="activeName" class="profile-tab" @tab-change="handleChangeTab">
          <el-tab-pane label="基本信息" name="first">
            <div style="margin: 1% auto">
              <el-form :model="profileForm" label-width="100px" style="width: 80%">
                <el-form-item label="用户名">
                  <el-input v-model="profileForm.username"/>
                </el-form-item>
                <el-form-item label="昵称">
                  <el-input v-model="profileForm.nickname"/>
                </el-form-item>
              </el-form>
            </div>
          </el-tab-pane>

          <el-tab-pane label="重置密码" name="second">
            <div style="margin: 1% auto">
              <el-form :model="passwordForm" label-width="100px" style="width: 80%">
                <el-form-item label="原密码">
                  <el-input type="password" v-model="passwordForm.oldPassword"/>
                </el-form-item>
                <el-form-item label="新密码">
                  <el-input type="password" v-model="passwordForm.password"/>
                </el-form-item>
              </el-form>
            </div>
          </el-tab-pane>
          <!--            <el-tab-pane label="Role" name="third">Role</el-tab-pane>-->
          <!--            <el-tab-pane label="Task" name="fourth">Task</el-tab-pane>-->
        </el-tabs>



      </div>

      <div style="position: absolute;bottom: 4%;right: 4%">
        <el-button size="large">取消</el-button>
        <el-button size="large" type="primary" @click="submit">确认</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import {loginUser, updateProfile, resetPassword} from "@/api/user";
import {ElMessage} from "element-plus";

export default {
  name: "Profile",
  created() {
    this.getProfile()
  },
  data() {
    return {
      title: '',
      activeName: 'first',
      profileForm: {},
      passwordForm: {}
    }
  },
  methods: {
    getProfile() {
      loginUser().then(resp => {
        this.profileForm = resp.data.data
      })
    },
    handleChangeTab() {
    },
    submit() {
      if (this.activeName === 'first') {
        updateProfile(this.profileForm).then(resp => {
          this.getProfile()
          this.$emit('updateProfile');
          ElMessage({
            type: 'success',
            message: '个人信息修改成功'
          })
        })
      } else if (this.activeName === 'second') {
        resetPassword(this.passwordForm).then(resp => {
          this.passwordForm = {}
          ElMessage({
            type: 'success',
            message: '密码重置成功'
          })
        })
      }
    }
  }
}
</script>

<style scoped>

.profile-tab {
  margin-top: 1%;
}

</style>