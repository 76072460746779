// 知识接口

import request from "@/util/request";

export function page(param) {
    return request({
        url: '/product/page',
        method: 'get',
        params: param
    })
}

export function saveOrUpdate(news) {
    return request({
        url: '/product',
        method: 'post',
        data: news
    })
}

export function getById(newsId) {
    return request({
        url: '/product/getById/' + newsId,
        method: 'get'
    })
}

export function deleteProduct(newsId) {
    return request({
        url: '/product/delete',
        method: 'delete',
        params: {
            id: newsId
        }
    })
}

export function uploadCover(data) {
    return request({
        url: '/product/uploadCover',
        method: 'post',
        data: data
    })
}

export function uploadHomeImg(data) {
    return request({
        url: '/product/uploadHomeImg',
        method: 'post',
        data: data
    })
}

export function uploadIntroImg(data) {
    return request({
        url: '/product/uploadIntroImg',
        method: 'post',
        data: data,
        headers: {
            'Content-type': 'multipart/form-data'
        }
    })
}

export function uploadHotImg(data) {
    return request({
        url: '/product/uploadHotImg',
        method: 'post',
        data: data,
        headers: {
            'Content-type': 'multipart/form-data'
        }
    })
}

export function uploadLandingImg(data) {
    return request({
        url: '/product/uploadLandingImg',
        method: 'post',
        data: data,
        headers: {
            'Content-type': 'multipart/form-data'
        }
    })
}