<!-- 新闻中心 -->

<template>
  <div>
    <el-card style="width: 55%;">
      <div>
        <div style="width: 100%;display: flex;justify-content: space-between">
          <span>
            <el-icon><BellFilled /></el-icon>
            <strong>&nbsp;通知管理</strong>
          </span>
        </div>

        <div style="margin-top: 30px;">
          <el-button type="primary" @click="handleAdd" :disabled="noticeList != null && noticeList.length > 0">新增</el-button>
        </div>

        <el-table stripe :data="noticeList" style="width: 100%;min-height: 550px;margin-top: 2%;" empty-text="暂无数据">
          <el-table-column prop="noticeContent" label="通知内容" width="400" />
          <el-table-column prop="createTime" label="创建时间"  />
          <el-table-column label="操作" >

            <template #default="scope">

              <el-button
                  type="danger"
                  :loading="scope.row.deleteLoading"
                  @click="handleDelete(scope.$index, scope.row)"
                  icon="Delete"
              >
                删除
              </el-button>

            </template>

          </el-table-column>
        </el-table>

      </div>
    </el-card>

    <!-- 通知信息弹框 -->
    <el-dialog
        v-model="open"
        :title="title"
        width="30%"
    >

      <el-form :model="form" label-width="120px">

        <el-form-item label="通知内容">
          <el-input v-model="form.noticeContent" style="width: 80%" />
        </el-form-item>

      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="open = false">取消</el-button>
          <el-button type="primary" @click="submitNotice">确定</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
import {
  Document,
  Edit,
  Delete,
  Picture as IconPicture,
  UploadFilled,
  Search
} from '@element-plus/icons-vue'

import {saveOrUpdate, getById, listNotice, deleteNotice, addNotice, getNotice} from "@/api/customer";
import {ElMessage, ElMessageBox} from "element-plus";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Customer",
  components: {
    Document, IconPicture, UploadFilled, Search
  },
  data() {
    return {
      queryParam: {},
      headers: {},
      news: {},
      newsId: null,
      coverLoading: false,
      coverFileList: [],
      sort: null,
      isTop: false,
      deleteLoading: false,
      msg: '',
      noticeList: [],
      open: false,
      openImg: false,
      title: '',
      form: {}
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      listNotice().then(resp => {
        this.noticeList = resp.data.data
      })
    },
    handleAdd() {
      this.title = '添加通知'
      this.open = true
    },
    handleEdit(row) {
      let newsId = row.id
      this.title = '修改新闻'
      getById(newsId).then(resp => {
        console.log(resp)
        this.form = resp.data.data
        this.open = true
      })
    },
    handleDelete(index, row) {
      ElMessageBox.confirm(
          '确认删除 "' + row.noticeContent + '"',
          '提示',
          {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning'
          }
      ).then(() => {
        row.deleteLoading = true
        deleteNotice(row.id).then(resp => {
          this.getList()
          row.deleteLoading = false
        })
      }).catch(() => {

      })

    },

    cancel() {
      this.open = false
      this.openImg = false
      this.form = {}
    },
    confirm() {
      saveOrUpdate(this.form).then(resp => {
        this.getList()
        this.open = false
        this.form = {}

      })
    },

    submitNotice() {
      addNotice(this.form).then(resp => {
        this.getList()
        this.open = false
      })
    }

  }
}
</script>

<style scoped>

</style>