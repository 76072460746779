// 知识接口

import request from "@/util/request";

export function page(param) {
    return request({
        url: '/productShowType/page',
        method: 'get',
        params: param
    })
}

export function list() {
    return request({
        url: '/productShowType/list',
        method: 'get'
    })
}

export function saveOrUpdate(news) {
    return request({
        url: '/productShowType',
        method: 'post',
        data: news
    })
}

export function getById(newsId) {
    return request({
        url: '/productShowType/getById/' + newsId,
        method: 'get'
    })
}

export function deleteProductShowType(newsId) {
    return request({
        url: '/productShowType/delete',
        method: 'delete',
        params: {
            id: newsId
        }
    })
}