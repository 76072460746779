<template>
  <div>
    <el-card style="width: 60%">
<!--      <div style="display: flex;align-items: center;height: 40px;line-height: 40px;">-->
<!--        <el-icon style="margin-right: 5px;"><UserFilled /></el-icon>用户管理-->
<!--      </div>-->

      <template #header>
        <div class="card-header" style="display: flex;justify-content: space-between">
          <span>用户列表</span>
<!--          <span style="width: 25%;display: flex;justify-content: right">-->
<!--                <el-input v-model="queryParam.gameName" clearable style="width: 65%" @keyup.enter="pageGame" placeholder="游戏名称" />-->
<!--                <el-button style="margin-left: 10px;" @click="pageGame">搜索</el-button>-->
<!--              </span>-->
        </div>
      </template>

      <div>
        <div style="margin: 10px;">
          <el-button type="primary" icon="Plus" @click="handleAdd">新增</el-button>
        </div>

        <el-table :data="userList" stripe style="width: 100%;" empty-text="暂无数据">
          <el-table-column prop="nickname" label="用户昵称" width="180" />
          <el-table-column prop="username" label="手机号码" />
          <el-table-column label="管理员" width="120">
            <template #default="scope">
              <el-tag
                  :type="scope.row.isAdmin? 'success' : 'info'"
                  disable-transitions
              >{{ scope.row.isAdmin ? '是' : '否' }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" width="180" />
          <el-table-column label="操作" >

            <template #default="scope">
              <el-button
                  type="primary"
                  @click="handleEdit(scope.row)"
                  icon="Edit"
                  text
              >
                编辑
              </el-button>

              <el-button
                  type="danger"
                  :loading="scope.row.deleteLoading"
                  @click="handleDelete(scope.$index, scope.row)"
                  icon="Delete"
                  text
              >
                删除
              </el-button>

            </template>
          </el-table-column>
        </el-table>
      </div>




    </el-card>


    <el-dialog
        :title="title"
        v-model="open"
        @close="this.form = {}"
        append-to-body
        style="width: 40%;"
    >
      <el-form :model="form" label-width="120px">
        <el-form-item label="手机号">
          <el-input v-model="form.username" style="width: 90%" maxlength="20" show-word-limit/>
        </el-form-item>

        <el-form-item label="昵称">
          <el-input v-model="form.nickname" style="width: 90%" maxlength="20" show-word-limit/>
        </el-form-item>

        <el-form-item label="管理员">
          <el-switch v-model="form.isAdmin"/>
        </el-form-item>

      </el-form>

      <template #footer>
            <span class="dialog-footer">
              <el-button @click="cancel">取消</el-button>
              <el-button type="primary" :loading="userLoading" @click="confirmUser">提交</el-button>
            </span>
      </template>

    </el-dialog>

  </div>
</template>

<script>
import {list, saveOrUpdate, getById, deleteUser} from "@/api/user";
import {UserFilled} from "@element-plus/icons-vue";

export default {
  name: "User",
  data() {
    return {
      open: false,
      userLoading: false,
      title: '',
      form: {},
      userList: [

      ]
    }
  },
  created() {
    this.list()
  },
  methods: {
    list() {
      list().then(resp => {
        this.userList = resp.data.data
      })
    },
    handleAdd() {
      this.title = '新增用户'
      this.open = true;
    },
    handleEdit(row) {
      getById(row.id).then(resp => {
        this.form = resp.data.data
        this.title = '修改用户'
        this.open = true
      })

    },

    handleDelete(index, row) {
      const id = row.id
      deleteUser(id).then(resp => {
        this.list()
      })
    },

    confirmUser() {
      this.userLoading = true
      saveOrUpdate(this.form).then(resp => {
        this.userLoading = false
        this.open = false
        this.list()

      }).catch(e => {
        this.userLoading = false
      })
    },

    cancel() {
      this.open = false
    }
  }
}
</script>

<style scoped>

</style>